@media only screen and (max-width: 768px) {
  .md\:hide-scrollbar::-webkit-scrollbar {
      display: none; /* Hides the scrollbar for WebKit browsers */
  }

  .md\:hide-scrollbar {
      -ms-overflow-style: none; /* Hides scrollbar for IE and Edge */
      scrollbar-width: none; /* Hides scrollbar for Firefox */
  }
}

.always-show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.always-show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}