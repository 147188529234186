:root {
  --primitives-color-blue:  #418BD4;
  --primitives-color-blue-dim:  #73a2d1;
  --primitives-color-red:  #EF4444;
  --primitives-color-red-dim:  #FEE2E2;
  --primitives-color-green:  #5DB250;
  --primitives-color-green-dim:  #DCFCE7;
}


:root {
  --color-primary: var(--primitives-color-blue);
  --color-primary-hover: var(--primitives-color-blue-dim);
  --color-error: var(--primitives-color-red);
  --color-error-dim: var(--primitives-color-red-dim);
  --color-success: var(--primitives-color-green);
  --color-success-dim: var(--primitives-color-green-dim);
  --color-ink: #111827;
  --color-ink-dim: #bbbbbb;
  --color-gray: #D1D5DB;
  --color-gray-dim: #F3F4F6;
  --color-background: #FFFFFF;
  --color-background-selected: #4ade80;

}

.dark {
  --color-primary: var(--primitives-color-blue);
  --color-primary-hover: var(--primitives-color-blue-dim);
  --color-error: var(--primitives-color-red);
  --color-error-dim: var(--primitives-color-red-dim);
  --color-success: var(--primitives-color-green);
  --color-success-dim: var(--primitives-color-green-dim);
  --color-ink: #d1d7e3;
  --color-ink-dim: #868991;
  --color-gray: #222224;
  --color-gray-dim: #111112;
  --color-background: #000000;
  --color-background-selected: #1c5731;
}
